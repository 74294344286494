/**
 * Get domain part of users email
 * @param {*} user
 * @returns {String|null} domain
 */
export function getUserEmailDomain(user) {
  let domain = null;

  if (user.email && typeof user.email === "string") {
    const parts = user.email.split("@");
    if (parts.length > 0) {
      domain = parts[parts.length - 1];
    }
  }

  return domain;
}
