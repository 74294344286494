const DEFAULT_LOCAL_DOMAIN = "edukamu";

var psl = require("psl");

/**
 * Check if currrent window location is localhost
 * @returns {boolean} is localhost?
 */
export const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

/**
 *
 * @param {*} text
 */
function isNotDomain(text) {
  return ["127", "192", "localhost"].includes(text);
}

/**
 *
 * @param {*} url
 */
export function extractHostname(url) {
  var hostname;

  // Find & remove protocol (http, ftp, etc.) and get hostname
  //
  if (url.indexOf("//") > -1) {
    hostname = url.split("/")[2];
  } else {
    hostname = url.split("/")[0];
  }

  // Find & remove port number
  //
  hostname = hostname.split(":")[0];

  // Find & remove "?"
  //
  hostname = hostname.split("?")[0];
  
  return hostname;
}

/**
 * TODO: needed?
 * @param {*} hostname
 */
export function realDomain(hostname) {
  return psl.parse(extractHostname(hostname));
}

/**
 *
 * @param {*} hostname
 */
export function domain(hostname) {
  hostname = hostname || window.location.hostname;

  var result = psl.parse(hostname);
  var tmp = hostname.split(".");
  var dom;

  if (isNotDomain(tmp[0]) || !result.domain) {
    dom = DEFAULT_LOCAL_DOMAIN;
  } else {
    dom = result.sld;

    dom = dom.replace("staging-", "");
    dom = dom.replace("test-", "");
  }

  return dom;
}

/**
 * 
 * @returns course path, according to hostname
 */
export function getCoursePath(course) {

  // If coursepath is set for course - we can just use it
  //
  if (course && typeof course.coursepath === "string" && course.coursepath.length > 0){
    return course.coursepath;
  }

  // We end up here if we for example request a course that does not exist - so we try to figure out coursePath according to some rules.
  //
  const hostname = extractHostname(window.location.href);
  var coursePath;

  switch (hostname) {
    case "127.0.0.1":
    case "localhost":
    case "dev.edukamu.fi":
    case "dev2.edukamu.fi":
    case "staging.edukamu.fi":
      coursePath = "https://dev-courses.web.app";
      break;

    case "test.edukamu.fi":
    case "test2.edukamu.fi":
      coursePath = "https://test-courses.web.app";
      break;

    case "stage.edukamu.fi":
    case "stage2.edukamu.fi":
      coursePath = "https://stage-courses.web.app";
      break;

    default:
      coursePath = "https://edukamu-courses.web.app";
      break;
  }

  if (course && course.hasOwnProperty("domains")) {
    if (course.domains[hostname]) {
      if (course.domains[hostname].hasOwnProperty("coursepath")) {
        coursePath = course.domains[hostname].coursepath;
      }
    }
  }

  return coursePath;
}
/**
 *  Muistettava päivittää kun domaineja tulee lisää ja kannat muuttuu
 * @param {*} hostname 
 * @returns 
 */
export function getInfoFromHostname(hostname) {
  switch (hostname) {
    case "127.0.0.1":
    case "localhost":
    case "dev.edukamu.fi":
    case "dev2.edukamu.fi":
    case "dev3.edukamu.fi":
    case "dev4.edukamu.fi":
    case "dev2-edukamu.web.app":
    case "staging.edukamu.fi":
    case "dev-edukamu-node.web.app":
    case "europe-west1-dev-edukamu.cloudfunctions.net": // 26.7.2012 (LR): eEnglish k�ytt�� t�t� osoitetta jostain syyst� getExerciseData funktiosta!
    case "test.edukamu.fi":
    case "test2.edukamu.fi":
      return "dev-edukamu";
    
    case "ms.edukamu.fi":
      return "kvedu-edukamu";
    case "india.edukamu.fi":
      return "kvedu-edukamu-india";
    case "cs.edukamu.fi":
      return "msc-edukamu";
    case "aiedu.edukamu.fi":
      return "aiedu-edukamu";
    case "kurssit.kunnollavauhtiin.fi":
    case "artikkelit.kunnollavauhtiin.fi":
    case "materiaali.kunnollavauhtiin.fi":
    case "articles.officeskillsaccelerator.com":
    case "material.officeskillsaccelerator.com":
    case "courses.officeskillsaccelerator.com":
      return "dkv-edukamu";
    default:
      return "edukamu";
  }
}
